<template>

    <div class="form-entry-component">

        <div class="form-entry-component-form">

            <div class="form-entry-component-form-header">

                <h3>Formulareintrag</h3>

                <div class="backend-component-loader" :class="{visible: isLoading}">
                    <div class="backend-component-loader-text">
                        Formular wird übersetzt ({{ locale.toUpperCase() }})...
                    </div>
                </div>

                <div class="form-entry-component-form-header-actions">
                    <a class="button success" @click="locale = ''" :class="{primary: locale === ''}">Originalsprache anzeigen</a>
                    <a @click="locale = 'de'; clickTranslate()" class="button" :class="{primary: locale === 'de'}" v-if="formEntry.language !== 'de'">DE</a>
                    <a @click="locale = 'fr'; clickTranslate()" class="button" :class="{primary: locale === 'fr'}" v-if="formEntry.language !== 'fr'">FR</a>
                    <a @click="locale = 'it'; clickTranslate()" class="button" :class="{primary: locale === 'it'}" v-if="formEntry.language !== 'it'">IT</a>
                    <a @click="locale = 'en'; clickTranslate()" class="button" :class="{primary: locale === 'en'}" v-if="formEntry.language !== 'en'">EN</a>
                    <a @click="locale = 'si'; clickTranslate()" class="button" :class="{primary: locale === 'si'}" v-if="formEntry.language !== 'si'">SI</a>
                    <a class="button error" @click="clickDelete()">Löschen</a>
                    <a class="button warning" @click="clickBack()">Zurück</a>
                </div>

            </div>

            <div class="form-entry-component-form-section" v-for="formGroup in form.config">

                <div class="form-entry-component-form-section-group-headline">{{ formGroup.name }}</div>

                <div class="row"></div>

                <template v-for="field in formGroup.fields">
                    <div class="row">
                        <div class="col-md-6" v-if="field.type === 'textarea'">
                            <template v-if="!locale">
                                <label>{{ field.name }}</label>
                                <textarea type="text" class="form-control" :value="getFieldData(field)" readonly></textarea>
                            </template>
                            <template v-else>
                                <label>{{ field.name }} (Übersetzung {{ locale.toUpperCase() }})</label>
                                <textarea type="text" class="form-control" :value="translateField(field.identifier)" readonly></textarea>
                            </template>
                        </div>
                        <div class="col-md-4" v-else-if="field.type === 'boolean'">
                            <template v-if="!locale">
                                <label>{{ field.name }}</label>
                            </template>
                            <template v-else>
                                <label>{{ field.name }} (Übersetzung {{ locale.toUpperCase() }})</label>
                            </template>
                            <!--input type="text" class="form-control" :value="getFieldDataBoolean(field)" readonly-->
                            <input type="checkbox" :checked="getFieldData(field)" readonly>
                        </div>
                        <div class="col-md-2" v-else-if="field.type === 'image'">
                            <label>{{ field.name }}</label>
                            <a :href="'/api/v1/files/view/'+getFieldData(field)?.id+'.'+getFieldData(field)?.extension"
                               v-if="getFieldData(field)?.id" target="_blank" class="button primary">Bild ansehen</a>
                            <span v-else>Kein Bild vorhanden.</span>
                        </div>
                        <div class="col-md-2" v-else-if="field.type === 'file'">
                            <label>{{ field.name }}</label>
                            <a :href="'/api/v1/files/download/'+getFieldData(field)?.id+'.'+getFieldData(field)?.extension"
                               v-if="getFieldData(field)?.id" target="_blank" class="button primary">Datei herunterladen</a>
                            <span v-else>Keine Datei vorhanden.</span>
                        </div>
                        <div class="col-md-4" v-else>
                            <template v-if="!locale">
                                <label>{{ field.name }}</label>
                                <input type="text" class="form-control" :value="getFieldData(field)" readonly>
                            </template>
                            <template v-else>
                                <label>{{ field.name }} (Übersetzung {{ locale.toUpperCase() }})</label>
                                <input type="text" class="form-control" :value="translateField(field.identifier)" readonly>
                            </template>
                        </div>
                    </div>
                </template>

                <div class="row"></div>
            </div>

        </div>

        <transition name="fade">
            <Modal v-if="modal" :config="modal"></Modal>
        </transition>

    </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Modal from './Modal';

export default {
    data() {
        return {
            locale: '',
            formEntry: {
                language: '',
                content: {},
                form: null,
                translations: {
                    de: {},
                    fr: {},
                    it: {},
                    en: {},
                    si: {},
                },
            },
            form: {},
            modal: null,
            fieldBooleanMapping: {
                de: {
                    true: 'Ja',
                    false: 'Nein',
                },
                fr: {
                    true: 'Oui',
                    false: 'Non',
                },
                it: {
                    true: 'Sì',
                    false: 'No',
                },
                en: {
                    true: 'Yes',
                    false: 'No',
                },
                si: {
                    true: 'Da',
                    false: 'Ne',
                },
            },
            isLoading: false,
        };
    },
    components: {
        Modal,
    },
    computed: {
        ...mapState({
            selectedForm: state => state.forms.form,
            selectedFormEntry: state => state.formEntries.formEntry,
        }),
    },
    methods: {
        clickDelete () {
            this.modal = {
                title: 'Eintrag löschen',
                description: 'Sind Sie sicher dass Sie diesen Eintrag unwiderruflich löschen möchten?',
                actions: [
                    {
                        label: 'Endgültig löschen',
                        class: 'error',
                        onClick: () => {
                            this.$store.dispatch('formEntries/delete', this.formEntry.id).then(() => {
                                this.$router.push('/forms/'+this.form.id+'/entries');
                            });
                        }
                    },
                    {
                        label: 'Abbrechen',
                        class: 'warning',
                        onClick: () => {
                            this.modal = null;
                        }
                    }
                ],
            };
        },
        clickBack () {
            this.$router.push('/forms/'+this.form.id+'/entries');
        },
        reload() {
            if(this.$route.params.id) {
                this.$store.commit('forms/set', {});
                this.$store.dispatch('forms/load', this.$route.params.id).then(() => {
                    this.form = {...this.selectedForm};
                });

                this.$store.dispatch('formEntries/load', this.$route.params.entryId).then(() => {
                    this.formEntry = {...this.selectedFormEntry};
                    this.isLoading = false;
                });
            }
        },
        getFieldData(field) {
            let fieldData = null;

            fieldData = this.formEntry.content[field.identifier] || null;

            return fieldData;
        },
        getFieldDataBoolean(field) {
            let value = this.getFieldData(field) ? 'true' : 'false';
            let lang = this.locale || this.formEntry.language;

            if(!lang || !this.fieldBooleanMapping[lang]) {
                return '';
            }

            return this.fieldBooleanMapping[lang][value] || '';
        },
        async clickTranslate() {
            if(this.formEntry.translations && this.formEntry.translations[this.locale]) {
                return;
            }

            this.isLoading = true;

            await this.$store.dispatch('formEntries/translate', { ...this.formEntry, selectedTranslation: this.locale });

            this.reload();
        },
        translateField(identifier) {
            if(!this.formEntry.translations || !this.formEntry.translations[this.locale]) {
                return '';
            }

            return this.formEntry.translations[this.locale][identifier] || '';
        },
    },
    created () {
        this.reload();
    }
}
</script>