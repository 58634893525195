<template>
    <div class="input-text">
        <label :for="id">
            <span class="material-icons" v-if="enableMerge" @click="$emit('merge', modelValue)">keyboard_backspace</span>
            {{ label }}
            <template v-if="required">*</template>
        </label>
        <textarea :id="id"
                  :value="modelValue"
                  :readonly="readonly"
                  :disabled="disabled"
                  :placeholder="placeholder"
                  :rows="rows"
                  @input="$emit('update:modelValue', $event.target.value)"
                  type="text"
                  class="form-control"></textarea>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                rand: Math.random(),
            };
        },
        emits: [
            'update:modelValue',
            'merge',
        ],
        props: {
            modelValue: {
                type: String,
                required: false,
                default: '',
            },
            id: {
                type: String,
                required: false,
                default () {
                    return Math.random();
                },
            },
            label: {
                type: String,
                required: false,
                default: '',
            },
            placeholder: {
                type: String,
                required: false,
                default: '',
            },
            required: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },
            enableMerge: {
                type: Boolean,
                required: false,
                default: false,
            },
            rows: {
                type: Number,
                required: false,
                default: 3,
            },
        },
    };
</script>